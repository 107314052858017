import React, { useRef } from 'react';
import { Container, Grid, Box, Divider, Typography, CssBaseline, IconButton, Button } from '@mui/material';
import PageButton from './PageButton';
import Travel from '../images/ListButtons/Travel.png';
import Food from '../images/ListButtons/Food.png';
import Entertainment from '../images/ListButtons/Entertainment.png';
import Health from '../images/ListButtons/Health.png';
import Technology from '../images/ListButtons/Technology.png';
import Lifestyle from '../images/ListButtons/Lifestyle.png';
import Business from '../images/ListButtons/Business.png';
import Sports from '../images/ListButtons/Sports.png';
import Education from '../images/ListButtons/Education.png';
import Environment from '../images/ListButtons/Environment.png';
import Fashion from '../images/ListButtons/Fashion.png';
import Culture from '../images/ListButtons/Culture.png';
import History from '../images/ListButtons/History.png';
import Pets from '../images/ListButtons/Pets.png';
import Books from '../images/ListButtons/Books.png';
import Gaming from '../images/ListButtons/Gaming.png';
import ScrollToTop from './ScrollToTop';

function Screen() {

  const categoriesRef = useRef(null);

  const categories = [
    { name: "Travel", image: Travel },
    { name: "Food", image: Food },
    { name: "Entertainment", image: Entertainment },
    { name: "Technology", image: Technology },
    { name: "Health", image: Health },
    { name: "Lifestyle", image: Lifestyle },
    { name: "Business", image: Business },
    { name: "Sports", image: Sports },
    { name: "Education", image: Education },
    { name: "Environment", image: Environment },
    { name: "Fashion", image: Fashion },
    { name: "Culture", image: Culture },
    { name: "History", image: History },
    { name: "Pets", image: Pets },
    { name: "Books", image: Books },
    { name: "Gaming", image: Gaming },
  ];

  const scrollToCategories = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container maxWidth="false" disableGutters>

      <ScrollToTop />
      <CssBaseline />
      <Box
        ref={categoriesRef}
        sx={{
          padding: 10,
          width: '100%',
        }}
      >
        <Grid container spacing={{ xs: 3, sm: 10, md: 15, lg: 4 }}>
          {categories.map((category, index) => (
            <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
              <Box display="flex" justifyContent="center" height="100%">
                <PageButton navigate={category.name.toLowerCase()} image={category.image} categoryName={category.name} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Screen;
