import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';
import GetAdminImage from '../services/GetAdminImage';
import GetAdminData from '../services/GetAdminData';
import '../styles/MainBanner.css';

function AdvertisementBanner() {
    const [bannerImages, setBannerImages] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false
    };

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const adminData = await GetAdminData();
                
                const advertisementImages = await Promise.all(
                    adminData.advertisements.map((ad) => GetAdminImage(ad))
                );

                setBannerImages(advertisementImages);
            } catch (error) {
            }
        };
        fetchAdminData();
    }, []);

    return (
        <Slider {...settings}>
            {bannerImages.map((image, index) => (
                <Box
                    key={index}
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 5,
                        height: { xs: '200px', sm: '350px', md: '500px' },
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                />
            ))}
        </Slider>
    );
}

export default AdvertisementBanner;
