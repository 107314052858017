// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Margin between slides */
.slick-slide {
    padding: 0 5px;
}

/* Adjust the parent element */
.slick-list {
    margin: 0 -5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MainBanner.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,cAAc;AAClB;;AAEA,8BAA8B;AAC9B;IACI,cAAc;AAClB","sourcesContent":["/* Margin between slides */\r\n.slick-slide {\r\n    padding: 0 5px;\r\n}\r\n\r\n/* Adjust the parent element */\r\n.slick-list {\r\n    margin: 0 -5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
