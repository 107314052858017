import axios from "axios"
import configs from  "./endpoints.json"

const adminService = axios.create({
    baseURL: `${configs.adminService}`
})

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const userService = axios.create({
    baseURL: `${configs.userService}`
})

const ApiInstance = {
    adminService,
    userAwsService,
    userService
}


export default ApiInstance;