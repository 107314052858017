import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import ArticlePage from './components/ArticlePage'

function App() {
  return (
    <Router>
        <Routes>
            <Route path='/' element={<Screen/>} />
            <Route path='/list' element={<ArticlePage/>} />
        </Routes>
    </Router>
  )
}

export default App