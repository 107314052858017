import { Box, Button, Typography } from '@mui/material';
import React  from 'react';
import { navigateToUrl } from 'single-spa';

function FavouriteButton({ path, text }) {

  const formatText = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: { xs: '100px', sm: '165px', md: '240px' },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 5,
        overflow: 'hidden',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Button
        variant="contained"
        sx={{
          width: '100%',
          height: '100%',
          fontSize: {
            xs: '1rem',
            sm: '1.5rem',
            md: '2rem',
            lg: '2.5rem',
          },
          backgroundColor: 'black',
          color: 'white',
          borderRadius: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() => navigateToUrl(`${path}`)}
      >
        <Typography
  variant="h3"
  sx={{
    letterSpacing: '1',
    textAlign: 'center',
    fontWeight: 550,
    color: 'white',
    textTransform: 'none',
    fontSize: {
      xs: '1.5rem',  
      sm: '1.8rem',  
      md: '2.5rem',  
      lg: '3rem',    
    },
  }}
>
  {formatText(text)}
</Typography>

      </Button>
    </Box>
  );
}

export default FavouriteButton;
