import React, { useEffect, useState } from 'react';
import { Container, Grid, Box } from "@mui/material";
import GetAdminData from '../services/GetAdminData';
import GetAdminImage from '../services/GetAdminImage';
import ReactPlayer from 'react-player';

function TopHeadline() {
  const [video, setVideo] = useState(null);
  const [coverImageMobile, setCoverImageMobile] = useState(null);
  const [coverImagePc, setCoverImagePc] = useState(null);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const adminData = await GetAdminData();

        const videoKey = adminData.homeVideo;
        const coverImageMobileKey = adminData.homeCoverImagePc;
        const coverImagePcKey = adminData.homeCoverImageMobile;

        const videoKeyUrl = await GetAdminImage(videoKey);
        const coverImageMobileKeyUrl = await GetAdminImage(coverImageMobileKey);
        const coverImagePcKeyUrl = await GetAdminImage(coverImagePcKey);

        setVideo(videoKeyUrl);
        setCoverImageMobile(coverImageMobileKeyUrl);
        setCoverImagePc(coverImagePcKeyUrl);


      } catch (error) {
        return null;
        // console.error("Error occurred when getting data", error);
      }
    };
    fetchAdminData();
  }, []);

  return (
    <Container
      maxWidth="100%"
      disableGutters
    >
      <Grid container spacing={0}>
        {/* Cover Image Section for xs, sm, md */}
        <Grid item xs={12}>
          <Box
              sx={{
                display: { xs: 'block', lg: 'none' },
                position: 'relative',
                height: { xs: '60vh', md: '75vh', sm: '75vh' },
                background: 'black',
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${coverImagePc})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  width: '100%',
                }}
              />
              </Box>
        </Grid>

        {/* Complex Layout for lg and up */}
        <Grid container item xs={12} lg={12} spacing={0} sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                background: 'black',
              }}
            >
              <ReactPlayer
                url={video}
                playing
                loop
                muted
                controls={false}
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  objectFit: 'cover',
                  pointerEvents: 'none',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${coverImageMobile})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  width: '100%',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TopHeadline;
