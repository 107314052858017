import * as React from 'react';
import { Button, Box, Typography } from "@mui/material";
import { navigateToUrl } from "single-spa";

function PageButton({ navigate, image, categoryName }) {

  const handleNavigate = () => {
    navigateToUrl(`articles/${navigate}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       }}
    >
      <Button
        sx={{
          width: { xs: '280px', sm: '200px', md: '200px', lg: '290px' },
          height: { xs: '280px', sm: '200px', md: '200px', lg: '290px' },
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '45px',
          color: '#fff',
          position: 'relative',   
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
        onClick={handleNavigate}
      >
         <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor:'black',
            borderBottomLeftRadius: '45px',
            borderBottomRightRadius: '45px',
            padding: '8px',
          }}
        >
          <Typography
            variant="subtitle1"
            align="center"
            letterSpacing={1}
            sx={{
              color: '#fff',
            }}
          >
            {categoryName}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}

export default PageButton;
