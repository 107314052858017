import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Box, Divider, Typography, CssBaseline, IconButton, Button, Stack } from '@mui/material';
import TopHeadline from './components/TopHeadline';
import MainBanner from './components/MainBanner'

import Register from './images/Register.png';
import exploreArticles from './images/ExploreArticle.png';
import WriteAnArticle from './images/WriteAnArticle.png';
import MyArticles from './images/MyArticles.png';
import Login from './images/Login.png';
import profile from './images/Profile.png';
import Empty from './images/Empty.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GetUserDetails from './services/GetUserData';
import FetchProfileImage from './services/FetchProfileImage'
import { navigateToUrl } from 'single-spa';
import LandingPageButton from './components/LandingPageButton';
import FavouriteButton from './components/FavouriteButton';
import AdvertisementBanner from './components/AdvertisementBanner';
import GetAdminData from './services/GetAdminData';
import GetAdminImage from './services/GetAdminImage';

function Screen() {
  const [profileImage, setProfileImage] = useState(profile);
  const [nickname, setNickname] = useState('');
  const [userData, setUserData] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [magazineCover, setMagazineCover] = useState('');

  const categoriesRef = useRef(null);

  const scrollToCategories = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const fetchAdminData = async () => {
        try {
            const adminData = await GetAdminData();
            const magazineCoverImageUrl = await GetAdminImage(adminData.magazineCoverImage);
            setMagazineCover(magazineCoverImageUrl);
        } catch (error) {

        }
    };
    fetchAdminData();
}, []);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = getUserAttribute(parsedUser, 'nickname');
      fetchUserDetails(nickname);
    }
  }, []);

  const fetchUserDetails = async (nickname) => {
    try {
      const userDetails = await GetUserDetails(nickname);
      setUserData(userDetails);
      setFavourites(userDetails.favourites);
      setNickname(userDetails.nickname);
      const imageUrl = await FetchProfileImage(userDetails.profileImageKey);
      setProfileImage(imageUrl);
    }
    catch (error) {
      return null;
      // console.error("Error occurred when getting user data", error);
    }
  };

  const getUserAttribute = (user, name) => {
    const attribute = user.UserAttributes.find(attr => attr.Name === name);
    return attribute ? attribute.Value : 'N/A';
  };

  return (
    <Container maxWidth="false" disableGutters>
      <CssBaseline />
      <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        <TopHeadline />
        <IconButton
          onClick={scrollToCategories}
          sx={{
            position: 'absolute',
            bottom: '50px',
            right: '50px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <KeyboardArrowDownIcon style={{ fontSize: 50 }} />
        </IconButton>
      </Box>
      <Box
        ref={categoriesRef}
        sx={{
          background: 'white',
          padding: 2,
          width: '100%'
        }}
      >
        <Grid container spacing={2} mb={0}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: { xs: '400px', sm: '450px', md: '685px' },
                backgroundImage: `url(${magazineCover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: 5,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  height: '100%',
                  fontSize: '2rem',
                  backgroundColor: 'rgba(0, 0, 0, 0.0)',
                  borderRadius: 5,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>

              <Grid item xs={3} sm={3} lg={3}>
                <LandingPageButton image={exploreArticles} path={"./list"} />
              </Grid>
              <Grid item xs={3} sm={3}>
                {nickname ? (
                  <LandingPageButton image={WriteAnArticle} path={"./writeArticle"} />
                ) : (
                  <LandingPageButton image={Login} path={"./signin"} />
                )}
              </Grid>
              <Grid item xs={3} sm={3}>
                {userData ? (
                  <LandingPageButton image={profileImage}  path={`./profile/${nickname}`} />
                ) : (
                  <LandingPageButton image={Register}  path={"./signup"} />
                )}
              </Grid>

              <Grid item xs={3} sm={3}>
                {nickname ? (
                  <LandingPageButton image={MyArticles}  path={"./myArticles"} />
                ) : (
                  <LandingPageButton image={Empty}  path={"/"} />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <MainBanner />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {nickname &&
        <Box
          sx={{
            background: 'white',
            padding: 2,
            width: '100%',
          }}
        >
          <Grid container spacing={2} mb={0}>
            <Grid item xs={12} sm={6}>
              <AdvertisementBanner />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                {favourites.map((favourite) => (
                  <Grid item xs={6} sm={6} lg={6} key={favourite}>
                    <FavouriteButton
                      image={favourite.toLowerCase()}
                      path={`./articles/${favourite.toLowerCase()}`}
                      text={favourite.toLowerCase()}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }

      <Box mb={3}/>
    </Container>
  );
}

export default Screen;
