import { Box, Button } from '@mui/material';
import React from 'react';
import { navigateToUrl } from 'single-spa';

function LandingPageButton({ image, path }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: {
          xs: '100%',    
          sm: '100%',    
          md: '100%',     
          lg: '100%',      
        },
        height: {
          xs: '80px',    
          sm: '80px',    
          md: '100px',   
          lg: '160px',    
        },
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 5,
        overflow: 'hidden',
        transition: 'background-image 0.3s ease',
      }}
    >
      <Button
        variant="contained"
        sx={{
          width: '100%',
          height: '100%',
          fontSize: {
            xs: '1rem',   
            sm: '1.5rem',  
            md: '2rem',    
            lg: '2.5rem',  
          },
          backgroundColor: 'rgba(80, 242, 196, 0.0)',  
          color: 'white',
          borderRadius: 5,
          '&:hover': {
            backgroundColor: 'rgba(64, 224, 181, 0.0)',  
          },
        }}
        onClick={() => navigateToUrl(`${path}`)}
      >
      </Button>
    </Box>
  );
}

export default LandingPageButton;
